import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';

const LoggedOutPage = () => {
    const phase = 'jury';
    return (
        <DefaultPage
            phase={phase}
            hideHelpMenu>
            <div className="grid-2--mobile grid-8--tablet grid-12--desktop">
                <p>Sie haben sich erfolgreich abgemeldet.</p>
                <p>
                    {
                        'jury' === phase &&
                        <span>Zurück zum <a href="/jury/login">Login</a></span>
                    }
                    {
                        'registration' === phase &&
                        <span>Zurück zum <a href="/user/login-register">Login</a></span>
                    }
                </p>
            </div>
        </DefaultPage>
    );
};

export default LoggedOutPage;
